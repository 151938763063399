


























































@use "@material/chips/mdc-chips";

.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  width: 0;
  height: 0;
}
.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::before {
  transform: none;
}
.mdc-chip .mdc-chip__ripple::before, .mdc-chip .mdc-chip__ripple::after {
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.icon-dots {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
  margin-left: 14px;
}

.chip-text-bold {
  font-weight: 700;
  color: #000000 60%;
}

.tools-fixed .mdc-chip {
  background-color: #F3F4F7;
  color: rgba(0, 0, 0, 0.38);
  margin-bottom: 20px;
}

.tools .mdc-chip {
  background-color: #FFFF;
  color: #00000B;
  border: lightgrey 1px solid;
  margin-bottom: 20px;
}

