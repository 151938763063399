


















































































































































































































































h3 {
  margin: 0;
  padding: 80px 0 35px;
}

h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #00000B;
  background-color: #EDF8FB;
  padding: 12px 26px;
  margin: 0;
}

.title-margin {
  margin-bottom: 40px;
}

.chips-box {
  display: flex;
  flex-wrap: wrap;
}

.toggle-box {
  display: flex;
  align-items: center;
  margin: 24px 0 60px;
}

.toggle-text {
  margin-right: 14px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #00000B;
}

.chips-box-bottom {
  margin-bottom: 40px;
}

.ghost {
  opacity: 0.5;
}

div[disabled=disabled] {
  pointer-events: none;
}

.list-group-item {
  width: auto;
}

