























































@use "@material/switch";
@use "@material/switch/mdc-switch";
@use "@material/button";

@include switch.core-styles;

$color_grey: rgba(0, 0, 0, 0.6);
$color_dark:  var(--color-text-secondary);

.mdc-switch {
  @include switch.toggled-on-color(#44BBDD);
  @include switch.toggled-off-color(#797979);
}
.mdc-button {
  @include button.shape-radius(50% , 0, 0);
  @include button.horizontal-padding(40px)	
}	

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}
.text-grey-uppercase {
  color: $color_dark;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 15px;}
.relation-orders-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  .mdc-switch-wrapper {
    width: 48%;
    font-size: 14px;
    line-height: 26px;
    .take-profit-order,
    .stop-loss-order,
    .oco-order {
      vertical-align: middle;
    }
    label {
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}
